// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper from "swiper";

const HERO = document.querySelector(".swiper-container--hero");

// init swiper
if (HERO && HERO.querySelectorAll(".swiper-slide").length > 1) {
    new Swiper (HERO, {
        autoplay: {
            delay: 15000,
        },
        loop: true,
        navigation: {
            nextEl: HERO.querySelector(".swiper-button--next"),
            prevEl: HERO.querySelector(".swiper-button--prev"),
        },
        pagination: {
            el: HERO.querySelector(".swiper-pagination"),
            clickable: true,
        },
        speed: 150,
    });
}

const LANDING = document.querySelector(".swiper-container--landing");

if (LANDING && LANDING.querySelectorAll(".swiper-slide").length > 1) {
    new Swiper(LANDING, {
        autoplay: {
            delay: 15000,
        },
        loop: true,
        navigation: {
            nextEl: LANDING.querySelector(".swiper-button--next"),
            prevEl: LANDING.querySelector(".swiper-button--prev"),
        },
        speed: 150,
    });
}
