// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

/**
 * Check if the alert exists
 */
const MAUTICFORM = document.querySelectorAll(".mauticform_wrapper .mauticform-radiogrp-radio");

/*
if (MAUTICFORM) {
    MAUTICFORM.classList.add("mauticform_test");
}
*/
/*
if (MAUTICFORM) {
    for (const RADIO in MAUTICFORM) {
        RADIO.classList.add("mauticform_test");
    }
}
*/

MAUTICFORM.forEach((RADIO) => {
    RADIO.classList.add("input--toggle", "input--toggle-radio");
});
