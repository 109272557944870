// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const FORM = document.querySelector(".search-form");

if (FORM) {
    const MODES = FORM.querySelectorAll("input[name=mode]");
    const INPUT = FORM.querySelector("input[name=s]");

    MODES.forEach((mode) => {
        mode.addEventListener("change", () => {
            FORM.setAttribute("target", mode.dataset.target);
            INPUT.setAttribute("placeholder", mode.dataset.placeholder);
        });
    });
}
