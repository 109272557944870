// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Tabby from "tabbyjs";

const MENUS = document.querySelectorAll("[data-tabs]");

if (MENUS.length > 0) {
    MENUS.forEach((menu) => {
        const TABS = new Tabby(`#${menu.getAttribute("id")}`);

        /**
         * Update tab when select changes
         */
        const SELECT = menu.parentElement.querySelector("select");

        if (SELECT) {
            SELECT.addEventListener("change", () => {
                TABS.toggle(SELECT.value);
            });

            /**
             * Update select when tab changes
             */
            document.addEventListener("tabby", (e) => {
                SELECT.value = e.target.getAttribute("href");
            }, false);
        }
    });
}
